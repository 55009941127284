import React from "react";
import { HcpPage, SEO, NumeroBlock, Boxy, AnchorBlock } from "components";
import { Row, Col } from "react-flexbox-grid";

import {
  HCPGetStarted,
  BecomePrescriberCallout
} from "components/shared/callouts";

const pageReferences = [
  `
  AVEED<sup>&reg;</sup> (Prescribing Information). Malvern, PA: Endo Pharmaceuticals Inc.
  `
];

const pageDescription = "AVEED® (testosterone undecanoate) injection CIII is a treatment option available through a REMS-qualified physician. Learn about the REMS program. See Safety & PI, including Boxed Warning.";

const RemsPage = () => {
  return (
    <HcpPage pageClassName="aveed-rems" references={pageReferences}>
      <SEO pageTitle="AVEED® REMS " pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Row>
          <Col xs={12}>
            <h1>
              AVEED<sup>&reg;</sup> Risk Evaluation and Mitigation Strategy
              (REMS) Program
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7}>
            <h2 className="pt-0">Why is enrollment required?</h2>
            <p>
              The AVEED<sup>&reg;</sup> REMS Program is required by the FDA. The
              program is designed to train all healthcare professionals who
              prescribe and/or administer AVEED<sup>&reg;</sup> about the risk
              of immediate post-injection reactions, specifically pulmonary oil
              microembolism (POME) and anaphylaxis.
            </p>
            <p>
              <strong>
                Among 3,556 patients in worldwide clinical trials<sup>1</sup><span className="super-asterisk">*</span>:{" "}
              </strong>
            </p>
            <ul className="green">
              <li>
                <span>9 POME events occurred in 8 patients</span>
              </li>
              <li>
                <span>2 events of anaphylaxis occurred</span>
              </li>
            </ul>
            <p className="footnote">
              *Patients treated with intramuscular testosterone undecanoate.
            </p>
            <p>
              In an 84-week clinical study using a dose of <nobr>750 mg</nobr>{" "}
              <nobr>(3 mL)</nobr> in <nobr>153 hypogonadal</nobr> men, the most
              commonly reported adverse <nobr>reactions (≥2%)</nobr> were acne,
              injection site pain, prostatic specific <nobr>antigen (PSA)</nobr>{" "}
              increased, estradiol increased, hypogonadism, fatigue,
              irritability, hemoglobin increased, insomnia, and mood swings.
            </p>
          </Col>
          <Col xs={12} md={5}>
            <div className="button-block">
              <AnchorBlock
                blockId="steps-for-setting"

              >
                Steps for Healthcare
                <br />
                Setting Enrollment
              </AnchorBlock>
              <AnchorBlock
                blockId="steps-for-provider"

              >
                Steps for Healthcare
                <br />
                Provider Enrollment
              </AnchorBlock>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Boxy
              title="Program Requirements"
              className="program-requirements-box"
              style={{ marginTop: 10 }}
            >
              <p>
                <strong>HEALTHCARE SETTINGS</strong> must be certified in the
                AVEED<sup>&reg;</sup> REMS program to be able to order AVEED
                <sup>&reg;</sup>. One representative needs to enroll per
                healthcare setting (the “Authorized Representative”).
              </p>
              <p>
                Under the conditions of the AVEED<sup>&reg;</sup> REMS Program,
                in order for a hospital pharmacy department to be able to
                transfer AVEED<sup>&reg;</sup> to another department with a
                different address within the hospital, both addresses must be
                REMS-certified Healthcare Settings, and must have the same
                Authorized Representative.
              </p>
              <p>
                <strong>PRESCRIBING HEALTHCARE PROVIDERS</strong> must be
                certified in the AVEED<sup>&reg;</sup> REMS Program to be able
                to prescribe AVEED<sup>&reg;</sup>.
              </p>
              <p>
                <strong>NON-PRESCRIBING HEALTHCARE PROVIDERS</strong> who will
                administer AVEED<sup>&reg;</sup> to patients must be trained on
                the AVEED<sup>&reg;</sup> REMS Education Program for Healthcare
                Providers.
              </p>
            </Boxy>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="h1" id="steps-for-setting">
              STEPS FOR HEALTHCARE SETTING ENROLLMENT
            </p>
            <NumeroBlock
              colorWare="green"
              numero="1"
              title="Get Started at AveedREMS.com"
            >
              Designate an Authorized Representative. This may be a prescribing
              or non-prescribing HCP, practice manager, or any responsible
              individual in a healthcare setting.
            </NumeroBlock>

            <NumeroBlock
              colorWare="green"
              numero="2"
              title="Review Training Materials"
            >
              Review the AVEED<sup>&reg;</sup> REMS Education Program for
              Healthcare Settings, including{" "}
              <nobr>Prescribing Information</nobr>.
            </NumeroBlock>

            <NumeroBlock
              colorWare="green"
              numero="3"
              title="Complete Enrollment Process"
            >
              Complete and sign the Healthcare Setting Enrollment Form. This
              enrollment must be <nobr>renewed every 2 years.</nobr>
            </NumeroBlock>

            <NumeroBlock colorWare="green" numero="4" title="Implementation">
              Implement the necessary staff training and processes to comply
              with the AVEED<sup>&reg;</sup> REMS Program requirements.
            </NumeroBlock>

            <p className="h1 orange-text" id="steps-for-provider">
              STEPS FOR HEALTHCARE PROVIDER ENROLLMENT
              </p>


            <NumeroBlock
              colorWare="orange"
              numero="1"
              title="Ensure That Your Healthcare Setting Is Enrolled"
            />

            <NumeroBlock
              colorWare="orange"
              numero="2"
              title="Get Started at AveedREMS.com"
            >
              Healthcare Providers can begin the process of becoming certified
              to prescribe AVEED<sup>&reg;</sup> by creating an account with the
              AVEED<sup>&reg;</sup> REMS Program at AveedREMS.com.
            </NumeroBlock>

            <NumeroBlock
              colorWare="orange"
              numero="3"
              title="Review Training Material"
            >
              After creating an account, Healthcare Providers will be able to
              access and review the AVEED<sup>&reg;</sup> REMS Education Program
              for Healthcare Providers, including the Prescribing Information.
            </NumeroBlock>

            <NumeroBlock
              colorWare="orange"
              numero="4"
              title="Complete Knowledge Assessment and Submit Enrollment Form"
            >
              Successfully complete the 10-question Knowledge Assessment, then
              sign and submit the one-time Healthcare Provider Enrollment Form
              to begin prescribing AVEED<sup>&reg;</sup>.
            </NumeroBlock>

            <p className="h1">
              For further information and to enroll in the AVEED<sup>&reg;</sup>{" "}
              REMS Program, please visit the AVEED<sup>&reg;</sup> REMS Program
              website at{" "}
              <a href="http://AveedREMS.com" target="_blank" rel="noopener noreferrer">
                AveedREMS.com
              </a>{" "}
              or call 1-855-755-0494 to request materials.
            </p>
          </Col>
        </Row>

        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <HCPGetStarted />
          </Col>
          <Col xs={12} md={6}>
            <BecomePrescriberCallout />
          </Col>
          <Col xs={12} className="pt-10">
            <p className="footnote"><sup >&dagger;</sup>Risk Evaluation and Mitigation Strategy.</p>
          </Col>
        </Row>
      </div>
    </HcpPage>
  );
};

export default RemsPage;
